var PrimaryMenu = ( function () {
  var mainMenu = $( '.main-navigation' );
  var mobileMenuToggle = $( '.mobile-menu-toggle' );
	var submenu = $('.menu-item-has-children');
	var backMenu = $('ul.sub-menu');

	function ToggleMenu(){
		if ( mainMenu.hasClass( 'show-menu' ) ) {
			 $('body').removeClass( 'no-scroll' );
			 mainMenu.removeClass( 'show-menu' );
			 mobileMenuToggle.removeClass('active');
			 submenu.removeClass('active');
			 console.log(backMenu);
			 removeBackMenu();
		}else{
			$('body').addClass( 'no-scroll' );
			mainMenu.addClass( 'show-menu' );
			mobileMenuToggle.addClass('active');
		}
	}

	mobileMenuToggle.on('click', function(e) {
		e.preventDefault();
		ToggleMenu();
	})

	function removeBackMenu(){
		backMenu.each(function(){
			$(this).children('.menu-item-back').remove();
		})
	}

	$(window).resize(function(){
		if ( window.innerWidth > 1115 ) {
	      if ( mainMenu.hasClass( 'show-menu' ) ) {
	        $('body').removeClass( 'no-scroll' );
	        mainMenu.removeClass( 'show-menu' );
	      }
	    }
	}).resize();

}() );


$(document).ready(function() {

  // Navbar: Checks to see if WP admin bar is showing, if so push the header down underneath the admin bar.
  var headerHeight = $( '#masthead' ).outerHeight();
  var wpAdminBarHeight = $('#wpadminbar').outerHeight();
  var prevScrollpos = window.pageYOffset;

  // console.log('headerHeight: ' + headerHeight);
  // console.log('wpAdminBarHeight: ' + wpAdminBarHeight);
  // console.log($('#wpadminbar').length);

  if( $('#wpadminbar').length = 1 )  {
    $('#masthead').css('margin-top', wpAdminBarHeight );
    // console.log('Yo, the wp admin bar is showing!');
  }

  // Content padding for the sticky nav
  $('#content').css('padding-top', headerHeight );

  // Hides the navba
  window.onscroll = function() {
    if (window.pageYOffset > 200) {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("masthead").style.top = "0";
      } else {
        document.getElementById("masthead").style.top = "-100px";
      }
      prevScrollpos = currentScrollPos;
    }
  }
  var MobileNavigation = ( function () {

    var width = $(window).width();

    if(width <= 1115){
      var headerHeight = $('.site-header').outerHeight() - 1;
      var totalHeight = $('.site-header').position();

      if( $('#wpadminbar').length = 1 )  {
        $('.main-navigation').css('top',totalHeight.top+headerHeight+wpAdminBarHeight+'px');
      } else {
        $('.main-navigation').css('top',totalHeight.top+headerHeight+'px');
      }
    }else{
      $('.main-navigation').css('top','');
    }

    $(window).resize(function(){
      var width = $(window).width();

      if(width <= 1115){
        NavigationToggle();
        var headerHeight = $('.site-header').outerHeight() - 1;
        var totalHeight = $('.site-header').position();

        $('.main-navigation').css('top',totalHeight.top+headerHeight+'px');
      }
      else {
        $('body').find('span.nav-caret').remove();
        $('.main-navigation').css('top','');
      }
    })
    .resize();//trigger the resize event on page load.

    function NavigationToggle(){
      var menuHasChildren = $('.menu-item-has-children');

      menuHasChildren.each(function(){
        $(this).append('<span class="nav-caret"><i class="fa fa-chevron-right"></i></span>');


        var caret = $(this).children('span');

        caret.on('click', function(e){
          e.preventDefault();
          $('li.menu-item').unbind( "click");
          var mainText = $(this).parent().children('a').text();
          var parentLi = $(this).parent();

          if( parentLi.hasClass('active') ){
            parentLi.removeClass('active');
          }else{
            parentLi.addClass('active');
          }

          $(this).parent().children('ul.sub-menu').prepend('<li class="menu-item-back"><a href="#"><i class="fa fa-chevron-left"></i><span>'+ mainText +'</span></a></li>').css('top',totalHeight.top+headerHeight+'px');

          var backLi = $('body').find('li.menu-item-back');

          backLi.on('click', function(e){
            e.preventDefault();
            $(this).parent().closest('li').removeClass('active');
            $(this).remove();
          });
        });

      });
    }
  }() );
}); // END $(document).ready(function()




var DisableHoverInMobile = ( function () {
	function hasTouch() {
    return 'ontouchstart' in document.documentElement
           || navigator.maxTouchPoints > 0
           || navigator.msMaxTouchPoints > 0;
	}

	if (hasTouch()) { // remove all :hover stylesheets
	    try { // prevent exception on browsers not supporting DOM styleSheets properly
	        for (var si in document.styleSheets) {
	            var styleSheet = document.styleSheets[si];
	            if (!styleSheet.rules) continue;

	            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
	                if (!styleSheet.rules[ri].selectorText) continue;

	                if (styleSheet.rules[ri].selectorText.match(':hover')) {
	                    styleSheet.deleteRule(ri);
	                }
	            }
	        }
	    } catch (ex) {}
	}
}() );
// var AccordionMenu = ( function () {
//   $('.main-navigation .primary-login li.blog-mega-menu > a').after(blog_submenu());
//
// 	var mainAcc = $('.blog-mega-menu__accordion-categories-item');
//
// 	$('.blog-mega-menu__accordion-categories-item').each( function() {
// 		var element = $(this);
//
// 		element.on( 'click', function(){
// 			mainAcc.removeClass( 'blog-mega-menu__accordion-categories-item--active' );
//
// 			if ( $(this).hasClass( 'blog-mega-menu__accordion-categories-item--active' ) ) {
// 				console.log('remove');
// 				$(this).removeClass( 'blog-mega-menu__accordion-categories-item--active' );
// 				$(this).removeClass( 'blog-mega-menu__accordion-categories-item--active' );
// 			} else {
// 				console.log('add');
// 				$(this).addClass( 'blog-mega-menu__accordion-categories-item--active' );
// 				$(this).addClass( 'blog-mega-menu__accordion-categories-item--active' );
// 			}
//
// 		})
//
// 	});
//
// }() );
